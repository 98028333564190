// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
topVisual

トップビジュアル

Markup:

*/
@media screen and (min-width: $breakpoint-sp + 1) {
	.topVisual {
		$this: &;
		$gutter-com: 20px;
		$side-width: 20%;
		padding: #{$gutter-com};

		display: -webkit-flex;
		display: flex;
		align-items: stretch;

		width: 100%;
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-left: #{$gutter-com};
			}
		}


		// // height: 582px;
		// display: grid;
		// row-gap: #{$gutter-com};
		// column-gap: #{$gutter-com};
		// grid-template:
		//	"header main01" 100% /
		//	320px calc( 100% - 340px );
		// grid-template:
		//	"header main01" 52%
		//	"header  main02" 44% /
		//	320px 2fr;

		&_header {
			position: relative;
			top: -10px;
			width: $side-width;
			// grid-area: header;
			// width: 18vw;
		}
		&_main {
			width: calc( 100% - #{$side-width} );
			padding: 0 45px;
			&-upper {
				// grid-area: main01;
			}
			// &-lower {
			//	grid-area: main02;
			// }
		}
	}
}
@media all and (-ms-high-contrast:none) {
	// .topVisual {
	//	$this: &;
	//	$gutter-com: 20px;
	//	display: -ms-grid;
	//	-ms-grid-columns: 320px calc( 100% - 340px );
	//	-ms-grid-rows: 100%;
	//	&_header {
	//		-ms-grid-row: 1;
	//		// -ms-grid-row-span: 2;
	//		-ms-grid-column: 1;
	//		-ms-grid-column-span: 1;
	//		grid-area: header;
	//	    margin-right: #{$gutter-com};
	//	}
	//	&_main {
	//		&-upper {
	//			-ms-grid-row: 1;
	//			-ms-grid-row-span: 1;
	//			-ms-grid-column: 2;
	//			-ms-grid-column-span: 1;
	//			grid-area: main01;
	//		}
	//		// &-lower {
	//		//	// -ms-grid-row: 2;
	//		//	-ms-grid-row-span: 1;
	//		//	-ms-grid-column: 2;
	//		//	-ms-grid-column-span: 1;
	//		//	grid-area: main02;
	//		// }
	//	}
	// }
}
@media screen and (min-width: $breakpoint-sp + 1) and (max-width: $breakpoint-pc + 20) {
	.topVisual {
		$this: &;
		padding: 10px 20px;
	}
	// .topVisual {
	//	$this: &;
	//	grid-template:
	//		"header main01" 100% /
	//		260px calc( 100% - 280px ) !important;
	//	// grid-template:
	//		// "header main01" 52%
	//		// "header  main02" 44% /
	//		// 260px 2fr;
	//	&_header {
	//		width: 240px !important;
	//	}
	// }
}
// @media screen and (max-width: $breakpoint-pc) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.topVisual {
		$this: &;
		$gutter-v: 1em;
		$gutter-com: .5em;
		padding: 10px 5px;
		// & > * {
		//	&:not(:first-child) {
		//		margin-top: #{$gutter-v};
		//	}
		// }
		&_main {
			&-upper {
			}
			&-lower {
				display: none;
			}
		}
	}
}