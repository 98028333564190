// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
topVisualCard

ビジュアルカード

Markup:

*/
.topVisualCard {
	$this: &;
	$gutter-v: 24px;

	// main image size
	$main-maxRatioWidth: 500;
	$main-maxRatioHeight: 280;
	$main-imgAspectRatio: calc( #{$main-maxRatioHeight} / #{$main-maxRatioWidth} * 100% ); // アスペクト比

	$main-maxWidth: 500px;
	$main-maxHeight: 280px;
	$main-maxWidthRatio: floor((( $main-maxWidth / $baseWidth ) * 10000% )) / 100;

	// main02 image size
	$main02-maxRatioWidth: 760;
	$main02-maxRatioHeight: 240;
	$main02-imgAspectRatio: calc( #{$main02-maxRatioHeight} / #{$main02-maxRatioWidth} * 100% ); // アスペクト比

	$main02-maxWidth: 760px;
	$main02-maxHeight: 240px;
	$main02-maxWidthRatio: floor((( $main02-maxWidth / $baseWidth ) * 10000% )) / 100;

	position: relative;
	&:first-child {
		margin-top: 0;
	}

	&_list {
		margin: 0;
		list-style-type: none;
		& > *:not(:first-child) {
			margin: #{$gutter-v} 0 0;
		}
	}
	&_item {
		@include boxShadow01;
		> a ,
		> div {
			position: relative;
			display: block;
			color: $baseColor;
			text-decoration: none;
		}
	}

	// _hero area
	&_hero {
	}
	&_figure {
		position: relative;
		display: block;
		margin: 0 auto;
		padding-top: #{$main-imgAspectRatio};
		width: 100%;
		overflow: hidden;
		text-align: center;
		// border: 1px solid $gray-border;
		-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
		@include boxShadow01;
	}
	&_img {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		max-width: 100%;
		max-height: 100%;
		width: 100%; // 「 cover 」時設定追加
		height: 100%; // 「 cover 」時設定追加
		object-fit: cover;
		font-family: 'object-fit: cover;';
		@include transitionType01;
	}

	// _mask area
	&_mask {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
				align-items: center;
		@include absolute(auto, 0, 0, 0);
		width: 100%;
		min-height: 100px;
		padding: 15px;
		background-color: rgba($white, .85);
		@include transitionType01;
	}
	&_maskinner {
	}
	&_headLine {
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
				align-items: center;
		-webkit-flex-wrap: wrap;
				flex-wrap: wrap;
		width: 100%;
	}
	&_logo {
		-webkit-flex: 0 0 auto;
		    -ms-flex: 0 0 auto;
		        flex: 0 0 auto;
		margin: 0;
		// line-height: 1;
	}
	&_logoImg {
		vertical-align: middle;
	}
	&_lead {
		margin-left: 1em;
		@include fs_vw(11);
	}
	&_title {
		@include fs_vw(14);
		font-weight: bold;
	}
	&_txt {
		margin-top: 10px;
		@include fs_vw(10);
	}

	// layout module
	&-type02 {
		#{$this}_figure {
			padding-top: #{$main02-imgAspectRatio};
		}
	}

	// layout module
	$gutter-grid-h: 20px;
	$gutter-grid-v: 20px;
	&-grid {
		#{$this}_list {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-wrap: wrap;
					flex-wrap: wrap;

			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		}
		&-v {
			#{$this}_list {
				margin: 0;
				display: block;
				overflow: hidden;
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
				}
			}
		}
		&-colAuto {
			#{$this}_item {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: auto !important;
			}
		}
	}
}
// hover style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.topVisualCard {
		$this: &;
		&_item {
			> a {
				&:hover {
					#{$this}_img {
						-webkit-transform: scale(1.02);
						    -ms-transform: scale(1.02);
						        transform: scale(1.02);
						-webkit-transition: 2s;
						        transition: 2s;
					}
				}
			}
		}
	}
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.topVisualCard {
		$this: &;

		// layout module
		$gutter-grid-h: 20px;
		$gutter-grid-v: 20px;
		&-grid {
			&-v-pc {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-pc {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
	}
}
@media screen and (min-width: $breakpoint-sp + 1) and (max-width: $breakpoint-pc + 20) {
	.topVisualCard {
		$this: &;

		&_item {
			&-01 {
				#{$this}_logoImg {
					height: 40px;
				}
			}
		}

		// _mask area
		&_mask {
			min-height: 80px;
			padding: 10px;
		}
		&_headLine {
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;
		}
		&_logoImg {
			height: 25px;
		}
		&_lead {
			margin: 5px 0 0;
			@include fs(15);
		}
		&_title {
			@include fs(18);
		}
		&_txt {
			@include fs(14);
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.topVisualCard {
		$this: &;
		$gutter-com: .5em;
		$gutter-v: 20px;

		// main image size
		$main-maxRatioWidth: 500;
		$main-maxRatioHeight: 280;
		$main-imgAspectRatio: calc( #{$main-maxRatioHeight} / #{$main-maxRatioWidth} * 100% ); // アスペクト比

		$main-maxWidth: 500px;
		$main-maxHeight: 280px;
		$main-maxWidthRatio: floor((( $main-maxWidth / $baseWidth ) * 10000% )) / 100;

		// main02 image size
		$main02-maxRatioWidth: 760;
		$main02-maxRatioHeight: 240;
		$main02-imgAspectRatio: calc( #{$main02-maxRatioHeight} / #{$main02-maxRatioWidth} * 100% ); // アスペクト比

		$main02-maxWidth: 760px;
		$main02-maxHeight: 240px;
		$main02-maxWidthRatio: floor((( $main02-maxWidth / $baseWidth ) * 10000% )) / 100;

		padding: 0;
		&_list {
			position: relative;
			& > *:not(:first-child) {
				margin: #{$gutter-v} 0 0;
			}
		}

		// _mask area
		&_mask {
			// position: relative;
			// top: auto;
			// right: auto;
			// bottom: auto;
			// left: auto;
			// min-height: 0;
			padding: 5px 10px;
			height: auto !important;
			min-height: 0;
		}
		&_maskinner {
		}
		&_headLine {
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;
		}
		&_logoImg {
			height: 25px;
		}
		&_logoImg {
			vertical-align: bottom;
		}
		&_lead {
			margin: 10px 0 0;
			@include fs(13);
		}
		&_title {
			@include fs(16);
		}
		&_txt {
			@include fs(13);
		}

		// layout module
		$gutter-grid-h: 12px;
		$gutter-grid-v: 20px;
		&-grid {
			#{$this}_list {
				margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
			}
			&-v-sp {
				#{$this}_list {
					margin: 0;
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/12 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					#{$this}_item {
						margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
						width: calc(100%/10 * #{$i} - #{$gutter-grid-h} - 4px)\9;
					}
				}
			}
			&-colAuto-sp {
				#{$this}_item {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}

		// slider layout
		[data-slcslider="topVisualCard"] {
			margin: 0;
			// padding-top: 45px;
			& > *:not(:first-child) {
				margin: 0 5px !important;
			}
			#{$this}_item {
				margin: 0 5px 26px!important;
			}
		}

		/* slickSlider base parts ( slider arrows set )
		--------------------------------------------*/
		// icon image
			$slickArrow-width: 30px;
			$slickArrow-height: 30px;

		&_arrow {
			display: block;
			position: absolute;
			// top: calc( #{$slickArrow-width} / 2 + 5px );
			top: 50%;
			margin: 0 !important;
			padding: 0;
			width: #{$slickArrow-width};
			height: #{$slickArrow-height};
			background-color: $mainColor01;
			border: 1px solid $mainColor01;
			outline: none;
			cursor: pointer;
			-webkit-border-radius: 100%;
			        border-radius: 100%;
			-webkit-transform: translate(0,-50%);
			        transform: translate(0,-50%);
			-webkit-transition: opacity 1s;
			        transition: opacity 1s;
			z-index: 5;
			&::before {
				display: none;
			}
			&::after {
				content: "";
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 50%;
				width: 10px;
				height: 10px;
				border-top: 2px solid $white;
				border-right: 2px solid $white;
				@include transitionType01;
			}
			> span {
				display: none;
			}
			&-prev {
				left: 0;
				// right: calc( #{$slickArrow-width} + 10px );
				&::after {
					margin-left: 2px;
					-webkit-transform: translate(-50%, -50%) rotate(-135deg);
					        transform: translate(-50%, -50%) rotate(-135deg);
				}
			}
			&-next {
				right: 0%;
				&::after {
					margin-left: -2px;
					-webkit-transform: translate(-50%, -50%) rotate(45deg);
					        transform: translate(-50%, -50%) rotate(45deg);
				}
			}
		}

		/* slickSlider base parts ( slider dots set )
		--------------------------------------------*/
		$slickDot-width: 12px;
		$slickDot-height: 12px;
		$slickDot-radius: 100%;
		.slick-dotted.slick-slider {
			margin-bottom: 0;
		}
		&_dots {
			z-index: 10;
			bottom: -10px;
			margin-top: 0;
			> li {
				margin: 0 5px;
				width: #{$slickDot-width};
				height: #{$slickDot-height};
				background-color: $gray-light;
				-webkit-border-radius: #{$slickDot-radius};
				        border-radius: #{$slickDot-radius};
				-webkit-transform: scale(1);
				    -ms-transform: scale(1);
				        transform: scale(1);
				-webkit-transition: .3s;
				        transition: .3s;
				overflow: hidden;
			}
			> li button {
				padding: 0;
				width: #{$slickDot-width};
				height: #{$slickDot-height};
				border: none;
				// border: 1px solid $white;
				-webkit-border-radius: #{$slickDot-radius};
				        border-radius: #{$slickDot-radius};
				-webkit-transition: .3s;
				        transition: .3s;
			}
			> li button:before {
				display: none;
			}

			> li.slick-active  {
				background-color: $mainColor01;
			}
		}
	}
}