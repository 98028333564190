@charset "UTF-8";
@import '_config';
@import '_core-min';
@import '_sprites/_sprite';
@import '_sprites/_sprite_mixin';

/* 〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓
	SAMPLE SITE
	TOP CSS
〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓〓 */
@import '_top/_mainVisual';
@import '_top/_topBanner';
@import '_top/_topBillboard';
@import '_top/_topNewArrival';
@import '_top/_topNews';
// @import '_top/_topPopular';
// @import '_top/_topSpecial';
@import '_top/_topVisual';
@import '_top/_topVisualCard';


/*
	gFooter
------------------------------------------------------------------------ */
.gFooter {
	$this: &;
	margin-top: 0;
}

/*
	topSection
------------------------------------------------------------------------ */
.topSection {
	$this: &;

	&:not(:first-child) {
		margin-top: 40px;
		padding-top: 55px;
		border-top: 1px solid $gray-border;
																									// border: 1px solid red;
	}

	&[id="topSpecial"] {
	}
	&[id="topNewArrival"] {
	}
	&[id="topPopular"] {
	}
}
// @media screen and (max-width: $breakpoint-pc) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.topSection {
		$this: &;

		&:not(:first-child) {
			margin-top: 20px;
			padding-top: 20px;
		}
	}
}

/*
	topHeadLine
------------------------------------------------------------------------ */
.topHeadLine {
	$this: &;
	position: relative;
	margin: 40px auto;
	padding-bottom: 22px;
	@include fs(36);
	font-weight: bold;
	line-height: 1.45;
	&::after {
		content: "";
		display: inline-block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 6%;
		height: 4px;
		background-color: $baseColor;
	}
	&:first-child {
		margin-top: 0;
	}
}
// @media screen and (max-width: $breakpoint-pc) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.topHeadLine {
		$this: &;
		margin: 24px auto;
		padding-bottom: 12px;
		@include fs(22);
		&::after {
			width: 14%;
		}
	}
}

/*
	topLayout
------------------------------------------------------------------------ */
.topLayout {
	$this: &;
	$gutter-com: 20px;
	$side-gutter-h: 40px;
	$side-width: 250px;
	$main-width: $baseWidth - $side-width - $side-gutter-h;
	// $main-width: @@px;

	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
			justify-content: space-between;
	-webkit-align-items: stretch;
			align-items: stretch;

	margin: 65px auto 0;
	padding: 0 0 65px;
	max-width: #{$baseWidth};
	@include cf;
	& > * {
		position: relative;
																									// border: 1px solid red;
	}

	// 共通スタイル
	// --------------------------------------
	&_main {
		width: 100%;
		max-width: #{$main-width};
		min-height:0;
		min-width: 0;
		-webkit-order: 2;
		    -ms-order: 2;
		        order: 2;
	}

	// _side area
	&_side {
		min-width: #{$side-width};
		width: #{$side-width};
	}
	&-sideLeft #{$this}_side ,
	&_side-left {
		margin-right: #{$side-gutter-h};
		-webkit-order: 1;
		    -ms-order: 1;
		        order: 1;
	}
	&-sideRight #{$this}_side ,
	&_side-right {
		margin-left: #{$side-gutter-h};
		-webkit-order: 3;
		    -ms-order: 3;
		        order: 3;
	}

	// 2カラム side-left ver.
	// --------------------------------------
	&-sideLeft {
		// _main area
		#{$this}_main {
			max-width: 100%;
		}

		// _side area
		#{$this}_side {
		}
	}

	// 2カラム side-right ver.
	// --------------------------------------
	&-sideRight {
		// _main area
		#{$this}_main {
			margin-left: 0;
			max-width: 100%;
		}

		// _side area
		#{$this}_side {
		}
	}
}
@media screen and (min-width: $breakpoint-sp + 1) and (max-width: $breakpoint-pc + 20) {
	.topLayout {
		$this: &;
		$gutter-com: 10px;
		$side-gutter-h: 30px;
		$side-width: 250px;
		$main-width: $baseWidth - $side-width - $side-gutter-h;
		// $main-width: @@px;

		margin-left: #{$gutter-com};
		margin-right: #{$gutter-com};

		// 共通スタイル
		// --------------------------------------
		&_main {
			max-width: #{$main-width};
		}

		// _side area
		&_side {
			min-width: #{$side-width};
			width: #{$side-width};
		}
		&-sideLeft #{$this}_side ,
		&_side-left {
			margin-right: #{$side-gutter-h};
		}
		&-sideRight #{$this}_side ,
		&_side-right {
			margin-left: #{$side-gutter-h};
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.topLayout {
		$this: &;
		$gutter-com: 15px;
		$side-gutter-h: 20px;
		$side-width:230px;
		$main-width: $baseWidth - $side-width - $side-gutter-h;
		// $main-width: @@px;

		-webkit-justify-content: flex-start;
				justify-content: flex-start;
		-webkit-flex-flow: column wrap;
		        flex-flow: column wrap;

		margin-top: 10px;
		padding: #{$gutter-com} .5em;
		max-width: none;

		// 共通スタイル
		// --------------------------------------
		&_main {
			width: 100%;
			max-width: none;
			margin: 0;
			-webkit-order: 1;
			    -ms-order: 1;
			        order: 1;
		}

		// _side area
		&_side {
			margin: 25px 0 0 !important;
			min-width: 0;
			width: 100%;
		}
		&-sideLeft #{$this}_side ,
		&_side-left {
			-webkit-order: 2;
			    -ms-order: 2;
			        order: 2;
		}
		&-sideRight #{$this}_side ,
		&_side-right {
			margin-left: 0;
		}
	}
}

