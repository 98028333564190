// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
topNews

お知らせ

Markup:

*/
.topNews {
	$this: &;
	$gutter-com: 20px;

	margin-top: 5px;
	padding: 0 #{$gutter-com};
	border-top: 1px solid $gray-border;
																									// border: 1px solid red;
	&_inner {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
				justify-content: space-between;
		-webkit-align-items: center;
				align-items: center;

		margin: 0 auto;
		padding: 1em 0;
		max-width: #{$baseWidth};
	}

	&_headliine {
		-webkit-flex: 0 0 auto;
		    -ms-flex: 0 0 auto;
		        flex: 0 0 auto;
		@include fs(18);
		font-weight: bold;
	}

	// &_controllers {
	//	padding: 0 1em;
	//	width: 155px;
	//	min-width: 155px;
	// }

	&_news {
		margin: 0;
		padding-left: 155px;
		min-width: 0;
		width: 100%;
		list-style-type: none;

		opacity: 0;
		visibility: hidden;
		&.slick-initialized ,
		&.is-view {
			opacity: 1;
			visibility: visible;
		}
	}
	&_item {
		> a ,
		> div {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
					align-items: center;

			position: relative;
			// display: block;
			@include fs(15);
			color: $baseColor;
			text-decoration: none;
		}
	}

	// _metas area
	&_metas {
		-webkit-flex: 0 0 auto;
		    -ms-flex: 0 0 auto;
		        flex: 0 0 auto;

		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
				align-items: center;
		& > * {
			margin: 0;
			vertical-align: middle;
			&:not(:first-child) {
				margin-left: 1.5em;
			}
		}
	}

	// _tags area
	&_tags {
		position: relative;
		margin: -5px 0 0 -5px;
		& > * {
			margin: 5px 0 0 5px;
		}
		.tag {
			padding: 4px 5px 3px;
			min-width: 110px;
			@include fs(12);
			font-weight: bold;
		}
	}

	// _dates area
	&_dates {
		margin-top: 0;
		color: $gray;
		@include ff-Montserrat;
		font-weight: bold;
		white-space: nowrap;
		& > * {
			margin: 0;
			&:not(:first-child) {
				margin-left: 5px;
			}
		}
	}

	&_title {
		margin: 0 0 0 2.8em;
		overflow: hidden;
		@include textEndLeader;
	}

	&_side {
		-webkit-flex: 0 0 auto;
		    -ms-flex: 0 0 auto;
		        flex: 0 0 auto;
	    margin-left: 1em;
	}
	&_more {
		position: relative;
		display: block;
		width: 150px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		color: $mainColor01;
		@include fs(14);
		border: 1px solid $mainColor01;
		text-decoration: none;
		&::after {
			content: "";
			display: inline-block;
			position: absolute;
			top: 50%;
			right: 10px;
			width: 8px;
			height: 8px;
			border-top: 1px solid $mainColor01;
			border-right: 1px solid $mainColor01;
			-webkit-transform: translate(-50%, -50%) rotate(45deg);
			        transform: translate(-50%, -50%) rotate(45deg);
			@include transitionType01;
		}
	}

	/* slickSlider base parts ( slider arrows set )
	--------------------------------------------*/
	// icon image
		$slickArrow-width: 30px;
		$slickArrow-height: 30px;

	&_arrow {
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		padding: 0;
		width: #{$slickArrow-width};
		height: #{$slickArrow-height};
		background-color: $white;
		border: 1px solid $mainColor01;
		outline: none;
		cursor: pointer;
		-webkit-border-radius: 100%;
		        border-radius: 100%;
		-webkit-transform: translate(0,-50%);
		        transform: translate(0,-50%);
		-webkit-transition: opacity 1s;
		        transition: opacity 1s;
		z-index: 5;
		&::before {
			display: none;
		}
		&::after {
			content: "";
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 12px;
			height: 12px;
			border-top: 2px solid $mainColor01;
			border-right: 2px solid $mainColor01;
			@include transitionType01;
		}
		> span {
			display: none;
		}
		&-prev {
			left: 3.3%;
			&::after {
				margin-left: 2px;
				-webkit-transform: translate(-50%, -50%) rotate(-135deg);
				        transform: translate(-50%, -50%) rotate(-135deg);
			}
		}
		&-next {
			left: 7.5%;
			&::after {
				margin-left: -2px;
				-webkit-transform: translate(-50%, -50%) rotate(45deg);
				        transform: translate(-50%, -50%) rotate(45deg);
			}
		}
	}
}
// @media screen and (max-width: $breakpoint-pc) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.topNews {
		$this: &;
		$gutter-com: .5em;

		padding: 0 #{$gutter-com};
		&_inner {
			-webkit-align-items: stretch;
					align-items: stretch;
			-webkit-flex-flow: column wrap;
			        flex-flow: column wrap;

			padding: 5px #{$gutter-com};
			max-width: none;
		}

		&_headliine {
			@include fs(15);
		}

		// &_controllers {
		//	padding: 0 1em;
		//	width: 155px;
		//	min-width: 155px;
		// }

		&_news {
			padding-left: 0;
		}
		&_item {
			> a ,
			> div {
				-webkit-align-items: stretch;
						align-items: stretch;
				-webkit-flex-flow: column;
				        flex-flow: column;
				@include fs(14);
			}
		}

		// _metas area
		&_metas {
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
					align-items: center;
			margin-top: 10px;
			& > * {
				&:not(:first-child) {
					margin-left: 1em;
				}
			}
		}

		// _tags area
		&_tags {
			.tag {
				min-width: 95px;
			}
		}

		&_title {
			margin: 5px 0 0;
		}

		&_side {
			text-align: right;
		    margin: 10px 0 0;
		}
		&_more {
			display: inline-block;
			width: 130px;
			&::after {
				right: 5px;
			}
		}

		/* slickSlider base parts ( slider arrows set )
		--------------------------------------------*/
		// icon image
			$slickArrow-width: 28px;
			$slickArrow-height: 28px;

		&_arrow {
			position: absolute;
			top: calc( -#{$slickArrow-width} / 2 + 5px );
			left: auto;
			right: 0;
			width: #{$slickArrow-width};
			height: #{$slickArrow-height};
			&-prev {
				right: calc( #{$slickArrow-width} + 10px );
			}
			&-next {
				right: 0%;
			}
		}
	}
}