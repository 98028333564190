// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
topBillboard

トップビジュアル内のロゴエリア

Markup:

*/
.topBillboard {
	$this: &;
	$gutter-com: 1em;

	width: 100%;
	height: 100%;
	// @include boxShadow01;
	// background: rgb(255,255,255);
	// background: linear-gradient(160deg, rgba(255,255,255,1) 0%, rgba(221,234,255,1) 100%);
	// border: 1px solid $gray-border;
	&_inner {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
				justify-content: center;
		-webkit-align-items: center;
				align-items: center;
		height: 100%;
	}
	&_inside {
		// padding: #{$gutter-com};
		text-align: center;
	}
	&_logo {
		margin: 0;
	}
	&_logoImg {
	}
	&_lead {
		margin-top: 60px;
		color: $mainColor01;
		@include fs(18);
	}
}
@media screen and (min-width: $breakpoint-sp + 1) and (max-width: $breakpoint-pc + 20) {
	.topBillboard {
		$this: &;
		&_lead {
			margin-top: 30px;
			@include fs(16);
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.topBillboard {
		$this: &;
		$gutter-com: .5em;

		display: none;

		&_inside {
			padding: #{$gutter-com};
		}
		&_logo {
			text-align: center;
		}
		&_logoImg {
			width: 180px;
		}
		&_lead {
			margin-top: 10px;
			@include fs(14);
		}
	}
}