// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
topBanner

バナー

Markup:

*/
.topBanner {
	$this: &;
	$gutter-com: 1em;
	$gutter-grid-h: 16px;
	$gutter-grid-v: 16px;

	&_grid {
		// display: -webkit-flex;
		// display: flex;
		// -webkit-align-items: stretch;
		//		align-items: stretch;
		// -webkit-flex-wrap: wrap;
		//		flex-wrap: wrap;
		@include inline_block-p;
		margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
		&-v {
			margin: 0;
			display: block;
			overflow: hidden;
		}
		&-colAuto {
			#{$this}_col {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				// width: auto !important;
				display: inline-block;
			}
		}
	}
	&_col {
		@include inline_block;
		position: relative;
		overflow: hidden;
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
			}
		}
		@for $i from 1 to 13 {
			&-#{$i}-10 {
				margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
				width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
			}
		}
	}

	&-left {
		#{$this}_grid {
			text-align: left;
			-webkit-justify-content: flex-start;
					justify-content: flex-start;
		}
	}
	&-center {
		#{$this}_grid {
			text-align: center;
			-webkit-justify-content: center;
					justify-content: center;
		}
		#{$this}_col {
			text-align: center;
		}
	}
	&-right {
		#{$this}_grid {
			text-align: right;
			-webkit-justify-content: flex-end;
					justify-content: flex-end;
		}
		#{$this}_col {
			text-align: right;
		}
	}
	&-sideArray {
		#{$this}_grid {
			-webkit-justify-content: space-between;
					justify-content: space-between;
		}
	}

	// page module
	&-topFooter {
		padding: 40px #{$gutter-com};
		background-color: $gray-bg;
		#{$this}_inner {
			margin: 0 auto;
			max-width: #{$baseWidth};
		}
	}
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
	.topBanner {
		$this: &;
		$gutter-grid-h: 16px;
		$gutter-grid-v: 16px;

		&_grid {
			&-v-pc {
				margin: 0;
			}
			&-colAuto-pc {
				#{$this}_col {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}

		&_col {
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10pc {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp) {
	.topBanner {
		$this: &;
		$gutter-com: .5em;
		$gutter-grid-h: 10px;
		$gutter-grid-v: 10px;

		padding-right: #{$gutter-com};
		padding-left: #{$gutter-com};
		&_grid {
			margin: calc( #{$gutter-grid-v} * -1 ) 0 0 calc( #{$gutter-grid-h} * -1 );
			&-v-sp {
				margin: 0;
			}
			&-colAuto-sp {
				#{$this}_col {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: auto !important;
				}
			}
		}
		&_col {
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/12 * #{$i} - #{$gutter-grid-h});
				}
			}
			@for $i from 1 to 13 {
				&-#{$i}-10sp {
					margin: #{$gutter-grid-v} 0 0 #{$gutter-grid-h};
					width: calc(100%/10 * #{$i} - #{$gutter-grid-h});
				}
			}
		}

		// page module
		&-topFooter {
			padding-top: 25px;
			padding-bottom: 25px;
			#{$this}_inner {
				max-width: none;
			}
		}
	}
}
