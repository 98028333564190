// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
topNewArrival

新着 BLOG

Markup:

*/
.topNewArrival {
	$this: &;
	$gutter-v: 20px;
	$main-gutter-h: 35px;
	$main-width: 400px;

	$side-gutter-h: 20px;
	$side-width: 120px;


	display: -webkit-flex;
	display: flex;
	justify-content: space-between;
	align-items: stretch;

	&_mainPost {
		width: #{$main-width};
		min-width: #{$main-width};
		margin-right: #{$main-gutter-h};

		#{$this}_item {
			border: none;
			> a ,
			> div {
				// color: $white;
				padding: 0;
				-webkit-flex-flow: column wrap;
				        flex-flow: column wrap;
			}
		}
		#{$this}_inside {
			display: grid;
			gap: $gutter-v;
		}
		#{$this}_hero {
			min-width: 0;
			width: 100%;
			margin: 0;
		}
		#{$this}_img {
			bottom: auto;
		}
	}

	&_list {
	}
	&_item {
		position: relative;
		margin: 0;
		border-bottom: 1px dotted $gray-border;
		> a ,
		> div {
			position: relative;
			display: block;
			padding: #{$gutter-v} 0;
			color: $baseColor;
			text-decoration: none;
		}
		&:first-child {
			> a ,
			> div {
				padding-top: 0;
			}
		}
	}

	&_insideBox {
		display: -webkit-flex;
		display: flex;
		align-items: stretch;
	}

	&_hero {
		min-width: #{$side-width};
		width: #{$side-width};
		margin-right: #{$side-gutter-h};
	}
	&_figure {
		@include aspectRatio(400, 268, contain);
		text-decoration: none;
		@include hoverImgStyle01;
		@include boxShadow01;
		// img {
		// 	@include transitionType02;
		// }
		&-noimage {
			@include noImageStyle;
		}
	}

	&_card {
	}

	&_title {
		margin: 0;
		@include fs(16);
		font-weight: bold;
		line-height: 1.35;
		&:not(:first-child) {
			margin-top: 10px;
		}
		a {
			display: inline;
			color: inherit;
			@include hoverTxtStyle01;
		}
	}

	// _metas area
	&_metas {
		display: -webkit-flex;
		display: flex;
		align-items: center;
		gap: 5px 1em;
		margin: 0;
		@include fs(14);
		&:not(:first-child) {
			margin-top: 10px;
		}
	}

	// _tags area
	&_tags {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 3px;
		margin: 0;
		.tag {
		}
	}

	// _dates area
	&_dates {
		@include fs(14);
		flex: 0 0 auto;

		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 5px;

		margin: 0;
		color: $gray;
		@include ff-Montserrat;
		font-weight: bold;
		white-space: nowrap;
	}

	&_lead {
		margin: 0;
		@include fs(14);
		line-height: 1.4;
		@include lineClamp(2);
		&:not(:first-child) {
			margin-top: 15px;
		}
	}

	&_sidePost {
		width: 100%;
	}
}
// hover style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
}
// @media screen and (max-width: $breakpoint-pc) {
// }
@media screen and (max-width: $breakpoint-sp) {
	.topNewArrival {
		$this: &;
		$gutter-com: .5em;
		$gutter-v: 14px;
		$main-gutter-h: 15px;
		$main-width: 100%;

		$side-gutter-h: 20px;
		$side-width: 120px;

		-webkit-flex-flow: column wrap;
						flex-flow: column wrap;

		&_mainPost {
			width: #{$main-width};
			min-width: 0;
			margin-right: 0;
			padding: 0 #{$gutter-com};
		}

		&_item {
			> a ,
			> div {
				padding: #{$gutter-v} 0;
			}
		}

		&_hero {
			min-width: #{$side-width};
			width: #{$side-width};
			margin-right: #{$side-gutter-h};
		}

		&_title {
			// @include fs(13);
			&:not(:first-child) {
				margin-top: 5px;
			}
		}

		// _metas area
		&_metas {
			gap: 5px 10px;
			// @include fs(13);
		}

		// _dates area
		&_dates {
			// @include fs(12);
		}

		&_lead {
			// @include fs(12);
			&:not(:first-child) {
				margin-top: 10px;
			}
		}

		&_sidePost {
			margin-top: #{$gutter-v};
			padding-top: #{$gutter-v};
			border-top: 1px solid $gray-border;
		}
	}
}
