// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
mainVisual

メインビジュアル

Markup:

*/
.mainVisual {
	$this: &;

	padding-bottom: 30px;
	/* setting
	--------------------------------------------*/
	&_slider {
		line-height: 0;
	}
	&_slideItem {
		padding: 6px;
	}
	&_figure {
		// @include imgAspect($main-imgAspectRatio, $objectFit);
		margin: 0 auto;
		text-align: center;
		background-color: #fff;
		-webkit-box-shadow: 1px 3px 6px 0 rgba(0,0,0, .3);
		        box-shadow: 1px 3px 6px 0 rgba(0,0,0, .3);
		img {
			margin: 0 auto;
		}
	}

	// 1スライドに複数のバナーを表示するパターン
	&_multiple {
		display: -webkit-flex;
		display: flex;
		justify-content: center;
		align-items: center;
		// flex-wrap: wrap;

		margin: -20px 0 0 -20px;
		list-style-type: none;
		& > * {
			margin: 20px 0 0 20px;
		}
		& + & {
			margin-top: -10px;
		}
	}
	&_multipleItem {
	}

	/* slickSlider base parts ( slider slick set )
	--------------------------------------------*/
	.slick-slider {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
				justify-content: center;
		// overflow: hidden;
		& > * {
			width: 100%;
			-webkit-flex: 0 0 100%;
			    -ms-flex: 0 0 100%;
			        flex: 0 0 100%;
		}
		&.slick-initialized {
			text-align: center;
			// #{$this}_slideItem {
			//	margin: 0;
			//	width: auto;
			//	float: none;
			// }
		}
	}
	.slick-slide {
		margin: 0 5px;
	}

	/* slickSlider base parts ( slider arrows set )
	--------------------------------------------*/
	$slickArrow-width: 45px;
	$slickArrow-height: 45px;
	.slick-arrow {
		display: block;
		position: absolute;
		top: 50%;
		padding: 0;
		width: #{$slickArrow-width};
		height: #{$slickArrow-height};
		background-color: $mainColor01;
		border: 1px solid $mainColor01;
		outline: none;
		cursor: pointer;
		-webkit-border-radius: 100%;
		        border-radius: 100%;
		-webkit-transform: translate(0,-50%);
		        transform: translate(0,-50%);
		-webkit-transition: opacity 1s;
		        transition: opacity 1s;
		z-index: 5;
		&::before {
			display: none;
		}
		&::after {
			content: "";
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 20px;
			height: 20px;
			border-top: 3px solid $white;
			border-right: 3px solid $white;
			@include transitionType01;
		}
		> span {
			display: none;
		}
	}
	.slick-prev {
		left: -#{$slickArrow-width};
		&::after {
			margin-left: 2px;
			-webkit-transform: translate(-50%, -50%) rotate(-135deg);
			        transform: translate(-50%, -50%) rotate(-135deg);
		}
	}
	.slick-next {
		right: -#{$slickArrow-width};
		&::after {
			margin-left: -2px;
			-webkit-transform: translate(-50%, -50%) rotate(45deg);
			        transform: translate(-50%, -50%) rotate(45deg);
		}
	}

	/* slickSlider base parts ( slider dots set )
	--------------------------------------------*/
	.slick-dots {
		> li {
			margin: 0 6px;
		}
	}
}
// PC style only
// --------------------------------------
@media screen and (min-width: $breakpoint-sp + 1) {
}
// SP style only
// --------------------------------------
@media screen and (max-width: $breakpoint-sp) {
	.mainVisual {
		$this: &;

		&:not(:first-child) {
			margin-top: 0;
		}
		&_inner {
		}

		/* slickSlider base parts ( slider slick set )
		--------------------------------------------*/

		/* slickSlider base parts ( slider arrows set )
		--------------------------------------------*/
		$slickArrow-width: 32px;
		$slickArrow-height: 32px;
		.slick-arrow {
			width: #{$slickArrow-width};
			height: #{$slickArrow-height};
			&::after {
				width: 10px;
				height: 10px;
			}
		}
		.slick-prev {
			left: 5px;
		}
		.slick-next {
			right: 5px;
		}

		/* slickSlider base parts ( slider dots set )
		--------------------------------------------*/
	}
}